// Import SVG Icons.
import React from "react";
import { FormControlLabel, Typography } from "@mui/material";
import { useFormikContext } from "formik";

import { ReactComponent as OneStory } from "./form-icons/one-story.svg";
import { ReactComponent as TwoStory } from "./form-icons/two-story.svg";
import { ReactComponent as ThreeStory } from "./form-icons/three-story.svg";
import { ReactComponent as Small } from "./form-icons/1999.svg";
import { ReactComponent as Medium } from "./form-icons/2000.svg";
import { ReactComponent as Large } from "./form-icons/3000.svg";
import { ReactComponent as Senior } from "./form-icons/senior.svg";
import { ReactComponent as Military } from "./form-icons/military.svg";
import { ReactComponent as Neither } from "./form-icons/neither.svg";
import { ReactComponent as Online } from "./form-icons/online.svg";
import { ReactComponent as Tv } from "./form-icons/tv.svg";
import { ReactComponent as Ctv } from "./form-icons/ctv.svg";
import { ReactComponent as Radio } from "./form-icons/radio.svg";
import { ReactComponent as Event } from "./form-icons/event.svg";
import { ReactComponent as PrintAd } from "./form-icons/print-ad.svg";
import { ReactComponent as Postcard } from "./form-icons/postcard.svg";
import { ReactComponent as Nascar } from "./form-icons/nascar.svg";
import { ReactComponent as Youtube } from "./form-icons/youtube.svg";
import { ReactComponent as StairLifts } from "./form-icons/stair-lifts.svg"
import { ReactComponent as WalkIn } from "./form-icons/walkin-store.svg";
import { ReactComponent as ReadyToHire } from "./form-icons/ready-forhire.svg";
import { ReactComponent as Planning } from "./form-icons/planning.svg";
import { ReactComponent as HomeOwner } from "./form-icons/home-owner.svg";
import { ReactComponent as FamilyMember } from "./form-icons/family-member.svg";
import { ReactComponent as CareTaker } from "./form-icons/care-taker.svg";
import { ReactComponent as YesSvg } from "./form-icons/yes.svg";
import { ReactComponent as NoSvg } from "./form-icons/no.svg";

import { ReactComponent as OnlineIconLHWS } from "./form-icons/online_159592eb07.svg";
import { ReactComponent as EventIconLHWS } from "./form-icons/event_15953862a0.svg";
import { ReactComponent as PrintAdIconLHWS } from "./form-icons/print-ad_15961499d0.svg";
import { ReactComponent as PostcardIconLHWS } from "./form-icons/postcard_1596016b91.svg";
import { ReactComponent as RadioIconLHWS } from "./form-icons/icons-LHWS-web_radio-off.svg";
import { ReactComponent as TVIconLHWS } from "./form-icons/lhws-tv.svg";
import { ReactComponent as CtvLHWS } from "./form-icons/lhws-ctv.svg";
import { ReactComponent as LHOneStory } from "./form-icons/leafhome-OneStoryIcon.svg";
import { ReactComponent as LHOneStoryActive } from "./form-icons/leafhome-OneStoryIcon-active.svg";
import { ReactComponent as LHTwoStory } from "./form-icons/leafhome-TwoStoryIcon.svg";
import { ReactComponent as LHTwoStoryActive } from "./form-icons/leafhome-TwoStoryIcon-active.svg";
import { ReactComponent as LHThreeStory } from "./form-icons/leafhome-ThreeStoryIcon.svg";
import { ReactComponent as LHThreeStoryActive } from "./form-icons/leafhome-ThreeStoryIcon-active.svg";
import { ReactComponent as LHSenior } from "./form-icons/LHSenior.svg";
import { ReactComponent as LHSeniorActive } from "./form-icons/LHSenior-active.svg";
import { ReactComponent as LHMilitary } from "./form-icons/LHMilitary.svg";
import { ReactComponent as LHMilitaryActive } from "./form-icons/LHMilitary-active.svg";
import { ReactComponent as LHNeither } from "./form-icons/LHNeither.svg";
import { ReactComponent as LHNeitherActive } from "./form-icons/LHNeither-active.svg";
import { ReactComponent as LHOnlineIcon } from "./form-icons/LH-Hand.svg";
import { ReactComponent as LHOnlineIconActive } from "./form-icons/LH-Hand-active.svg";
import { ReactComponent as LHEventIcon } from "./form-icons/LH-Event.svg";
import { ReactComponent as LHEventIconActive } from "./form-icons/LH-Event-active.svg";
import { ReactComponent as LHPrintIcon } from "./form-icons/LH-Print.svg";
import { ReactComponent as LHPrintIconActive } from "./form-icons/LH-Print-active.svg";
import { ReactComponent as LHPostcardIcon } from "./form-icons/LH-Mail.svg";
import { ReactComponent as LHPostcardIconActive } from "./form-icons/LH-Mail-active.svg";
import { ReactComponent as LHNascarIcon } from "./form-icons/LH-Nascar.svg";
import { ReactComponent as LHNascarIconActive } from "./form-icons/LH-Nascar-active.svg";
import { ReactComponent as LHYoutubeIcon } from "./form-icons/LH-Youtube.svg";
import { ReactComponent as LHYoutubeIconActive } from "./form-icons/LH-Youtube-active.svg";
import { ReactComponent as LHRadioIcon } from "./form-icons/LH-Radio.svg";
import { ReactComponent as LHRadioIconActive } from "./form-icons/LH-Radio-active.svg";
import { ReactComponent as LHTVIcon } from "./form-icons/LH-TV.svg";
import { ReactComponent as LHTVIconActive } from "./form-icons/LH-TV-active.svg";
import { ReactComponent as LHReadyToHireIcon } from "./form-icons/LH-ReadyToHire.svg";
import { ReactComponent as LHReadyToHireIconActive } from "./form-icons/LH-ReadyToHire-active.svg";
import { ReactComponent as LHPlanningIcon } from "./form-icons/LH-Planning.svg";
import { ReactComponent as LHPlanningIconActive } from "./form-icons/LH-Planning-active.svg";
import { ReactComponent as LHCareTakerIcon } from "./form-icons/LH-CareTaker.svg";
import { ReactComponent as LHCareTakerIconActive } from "./form-icons/LH-CareTaker-active.svg";
import { ReactComponent as LHHomeownerIcon } from "./form-icons/LH-Homeowner.svg";
import { ReactComponent as LHHomeownerIconActive } from "./form-icons/LH-Homeowner-active.svg";
import { ReactComponent as LHHouseHeadIcon } from "./form-icons/LH-HouseHead.svg";
import { ReactComponent as LHHouseHeadIconActive } from "./form-icons/LH-HouseHead-active.svg";
import { ReactComponent as LHNoIcon } from "./form-icons/LH-No.svg";
import { ReactComponent as LHNoIconActive } from "./form-icons/LH-No-active.svg";
import { ReactComponent as LHYesIcon } from "./form-icons/LH-Yes.svg";
import { ReactComponent as LHYesIconActive } from "./form-icons/LH-Yes-active.svg";
import { ReactComponent as LHWellWaterIcon } from "./form-icons/LH-WellWater.svg";
import { ReactComponent as LHWellWaterIconActive } from "./form-icons/LH-WellWater-active.svg";
import { ReactComponent as LHCityWaterIcon } from "./form-icons/LH-CityWater.svg";
import { ReactComponent as LHCityWaterIconActive } from "./form-icons/LH-CityWater-active.svg";
import { ReactComponent as LHUnsureIcon } from "./form-icons/LH-Unknown.svg";
import { ReactComponent as LHUnsureIconActive } from "./form-icons/LH-Unknown-active.svg";
import { ReactComponent as LHWaterOdorIcon } from "./form-icons/LH-WaterOdor.svg";
import { ReactComponent as LHWaterOdorIconActive } from "./form-icons/LH-WaterOdor-active.svg";
import { ReactComponent as LHNoOdorIcon } from "./form-icons/LH-NoOdor.svg";
import { ReactComponent as LHNoOdorIconActive } from "./form-icons/LH-NoOdor-active.svg";
import { ReactComponent as LHFlexibleIcon } from "./form-icons/LH-Flexible.svg";
import { ReactComponent as LHFlexibleIconActive } from "./form-icons/LH-Flexible-active.svg";
import { ReactComponent as PodCast } from "./form-icons/lf-podcast-icon.svg";
import { ReactComponent as LHPodCastIcon } from "./form-icons/LH-podcast.svg";
import { ReactComponent as LHPodCastIconActive } from "./form-icons/LH-podcast-active.svg";
import { ReactComponent as LHCtvIcon } from "./form-icons/LH-Stream-TV.svg";
import { ReactComponent as LHCtvIconActive } from "./form-icons/LH-Stream-TV-active.svg";

const WrapFormControlLabel = ({ keyText, textLabel, ControlIcon, selected, textClass = '' }) => {
  return (
    <FormControlLabel
      value={keyText }
      className= {`${keyText} form-icon`}
      control = {ControlIcon}
      label = {
        <Typography
          variant="body4"
          className={`image-wrapper-label-text ${textClass} ${selected ? "selected" : ""}`}
        >
          { textLabel }
        </Typography>
      }
      labelPlacement={"bottom"}
      sx={{
        ml: 0,
        width: "75px",
        textAlign: "center"
      }}
    >
    </FormControlLabel>
  )
}

export const OneStoryIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"oneStory"}
      textLabel={"1 Story"}
      ControlIcon={(<OneStory
        className={`custom-img ${values.homeLevel === "oneStory"
          ? "with-border"
          : ""
          }`}
        alt="oneStory"
        onClick={handleClick}
      />)}
      selected={values.homeLevel === "oneStory"}
    >
    </WrapFormControlLabel>
  )
};

export const LHOneStoryIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"OneStory"}
      textLabel={"1 Story"}
      ControlIcon={(<div><LHOneStory
        className={`custom-img ${values.homeLevel === "oneStory"
          ? "with-border"
          : ""
          }`}
        alt="oneStory"
        onClick={handleClick}
      />
      <LHOneStoryActive
        className={`custom-img hide ${values.homeLevel === "oneStory"
          ? ""
          : "hide"
          }`}
        alt="oneStory"
        onClick={handleClick}
      /></div>)}
      selected={values.homeLevel === "oneStory"}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const TwoStoryIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"twoStory"}
      textLabel={"2 Story"}
      ControlIcon={(<TwoStory
        className={`custom-img ${values.homeLevel === "twoStory"
          ? "with-border"
          : ""
          }`}
        alt="twoStory"
        onClick={handleClick}
      />)}
      selected={values.homeLevel === "twoStory"}
    >
    </WrapFormControlLabel>
  )
};

export const LHTwoStoryIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"twoStory"}
      textLabel={"2 Story"}
      ControlIcon={(<div><LHTwoStory
        className={`custom-img ${values.homeLevel === "twoStory"
          ? "with-border"
          : ""
          }`}
        alt="twoStory"
        onClick={handleClick}
      />
      <LHTwoStoryActive
        className={`custom-img hide ${values.homeLevel === "twoStory"
          ? ""
          : "hide"
          }`}
        alt="twoStory"
        onClick={handleClick}
      /></div>)}
      selected={values.homeLevel === "twoStory"}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const ThreeStoryIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"threeStory"}
      textLabel={"3 Story"}
      ControlIcon={(<ThreeStory
        className={`custom-img ${values.homeLevel === "threeStory"
          ? "with-border"
          : ""
          }`}
        alt="threeStory"
        onClick={handleClick}
      />)}
      selected={values.homeLevel === "twoStory"}
    >
    </WrapFormControlLabel>
  )
};

export const LHThreeStoryIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"threeStory"}
      textLabel={"3 Story"}
      ControlIcon={(<div><LHThreeStory
        className={`custom-img ${values.homeLevel === "threeStory"
          ? "with-border"
          : ""
          }`}
        alt="threeStory"
        onClick={handleClick}
      />
      <LHThreeStoryActive
        className={`custom-img hide ${values.homeLevel === "threeStory"
          ? ""
          : "hide"
          }`}
        alt="threeStory"
        onClick={handleClick}
      /></div>)}
      selected={values.homeLevel === "threeStory"}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const SmallIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"1999"}
      textLabel={"0-1999"}
      ControlIcon={(<Small
        className={`custom-img ${values.squareFootage === "1999"
          ? "with-border"
          : ""
          }`}
        alt="1999"
        onClick={handleClick}
      />)}
      selected={values.squareFootage === "1999"}
    >
    </WrapFormControlLabel>
  )
};

export const LHSmallIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"1999"}
      textLabel={"0-1999"}
      ControlIcon={(<div><LHOneStory
        className={`custom-img ${values.squareFootage === "1999"
          ? "with-border"
          : ""
          }`}
        alt="1999"
        onClick={handleClick}
      />
      <LHOneStoryActive
        className={`custom-img ${values.squareFootage === "1999"
          ? ""
          : "hide"
          }`}
        alt="1999"
        onClick={handleClick}
      />
      </div>)}
      selected={values.squareFootage === "1999"}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const MediumIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"2000"}
      textLabel={"2000-2999"}
      ControlIcon={(<Medium
        className={`custom-img ${values.squareFootage === "2000"
          ? "with-border"
          : ""
          }`}
        alt="2000-2999"
        onClick={handleClick}
      />)}
      selected={values.squareFootage === "2000"}
    >
    </WrapFormControlLabel>
  )
};

export const LHMediumIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"2000"}
      textLabel={"2000-2999"}
      ControlIcon={(<div><LHOneStory
        className={`custom-img ${values.squareFootage === "2000"
          ? "with-border"
          : ""
          }`}
        alt="2000-2999"
        onClick={handleClick}
      />
      <LHOneStoryActive
        className={`custom-img ${values.squareFootage === "2000"
        ? ""
        : "hide"
        }`}
        alt="2000-2999"
        onClick={handleClick}
      />
      </div>)}
      selected={values.squareFootage === "2000"}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const LargeIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"3000"}
      textLabel={"3000+"}
      ControlIcon={(<Large
        className={`custom-img ${values.squareFootage === "3000"
          ? "with-border"
          : ""
          }`}
        alt="3000+"
        onClick={handleClick}
      />)}
      selected={values.squareFootage === "3000"}
    >
    </WrapFormControlLabel>
  )
};

export const LHLargeIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"3000"}
      textLabel={"3000+"}
      ControlIcon={(<div><LHOneStory
        className={`custom-img ${values.squareFootage === "3000"
          ? "with-border"
          : ""
          }`}
        alt="3000+"
        onClick={handleClick}
      />
      <LHOneStoryActive
        className={`custom-img ${values.squareFootage === "3000"
        ? ""
        : "hide"
        }`}
        alt="3000+"
        onClick={handleClick}
      />
      </div>)}
      selected={values.squareFootage === "3000"}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const SeniorIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"senior"}
      textLabel={"Senior"}
      ControlIcon={(<Senior
        className={`custom-img ${values.groupLevel.indexOf("senior") >= 0
          ? "with-border"
          : ""
          }`}
        alt="Senior"
        onClick={handleClick}
      />)}
      selected={values.groupLevel.indexOf("senior") >= 0}
    >
    </WrapFormControlLabel>
  )
};

export const LHSeniorIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"senior"}
      textLabel={"Senior"}
      ControlIcon={(<div><LHSenior
        className={`custom-img ${values.groupLevel.indexOf("senior") >= 0
          ? "with-border"
          : ""
          }`}
        alt="Senior"
        onClick={handleClick}
      />
      <LHSeniorActive
        className={`custom-img ${values.groupLevel.indexOf("senior") >= 0
          ? ""
          : "hide"
          }`}
        alt="Senior"
        onClick={handleClick}
      />
      </div>)}
      selected={values.groupLevel.indexOf("senior") >= 0}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const MilitaryIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"military"}
      textLabel={"Military"}
      ControlIcon={(<Military
        className={`custom-img ${values.groupLevel.indexOf("military") >= 0
          ? "with-border"
          : ""
          }`}
        alt="Military"
        onClick={handleClick}
      />)}
      selected={values.groupLevel.indexOf("military") >= 0}
    >
    </WrapFormControlLabel>
  )
};

export const LHMilitaryIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"military"}
      textLabel={"Military"}
      ControlIcon={(<div><LHMilitary
        className={`custom-img ${values.groupLevel.indexOf("military") >= 0
          ? "with-border"
          : ""
          }`}
        alt="Military"
        onClick={handleClick}
      />
      <LHMilitaryActive
        className={`custom-img ${values.groupLevel.indexOf("military") >= 0
          ? ""
          : "hide"
          }`}
        alt="Military"
        onClick={handleClick}
      />
      </div>)}
      selected={values.groupLevel.indexOf("military") >= 0}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const NeitherIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"neither"}
      textLabel={"Neither"}
      ControlIcon={(<Neither
        className={`custom-img ${values.groupLevel === "neither"
          ? "with-border"
          : ""
          }`}
        alt="Neither"
        onClick={handleClick}
      />)}
      selected={values.groupLevel === "neither"}
    >
    </WrapFormControlLabel>
  )
};

export const LHNeitherIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"neither"}
      textLabel={"None"}
      ControlIcon={(<div><LHNeither
        className={`custom-img ${values.groupLevel === "neither"
          ? "with-border"
          : ""
          }`}
        alt="None"
        onClick={handleClick}
      />
      <LHNeitherActive
        className={`custom-img ${values.groupLevel === "neither"
          ? ""
          : "hide"
          }`}
        alt="None"
        onClick={handleClick}
      />
      </div>)}
      selected={values.groupLevel === "neither"}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const OnlineIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"41"}
      textLabel={"Online"}
      ControlIcon={(<Online
        className={`custom-img ${values.adLevel === "41"
          ? "with-border"
          : ""
          }`}
        alt="Online"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "41"}
    >
    </WrapFormControlLabel>
  )
};

export const LHOnline = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"41"}
      textLabel={"Online"}
      ControlIcon={(<div><LHOnlineIcon
        className={`custom-img ${values.adLevel === "41"
          ? "with-border"
          : ""
          }`}
        alt="Online"
        onClick={handleClick}
      />
      <LHOnlineIconActive
        className={`custom-img ${values.adLevel === "41"
          ? ""
          : "hide"
          }`}
        alt="Online"
        onClick={handleClick}
      />
      </div>)}
      selected={values.adLevel === "41"}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const TvIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"81"}
      textLabel={"TV"}
      ControlIcon={(<Tv
        className={`custom-img ${values.adLevel === "81"
          ? "with-border"
          : ""
          }`}
        alt="TV"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "81"}
    >
    </WrapFormControlLabel>
  )
};

export const LHTV = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"81"}
      textLabel={"TV"}
      ControlIcon={(<div><LHTVIcon
        className={`custom-img ${values.adLevel === "81"
          ? "with-border"
          : ""
          }`}
        alt="TV"
        onClick={handleClick}
      />
      <LHTVIconActive
        className={`custom-img ${values.adLevel === "81"
          ? ""
          : "hide"
          }`}
        alt="TV"
        onClick={handleClick}
      />
      </div>)}
      selected={values.adLevel === "81"}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const CtvIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"41TCTV"}
      textLabel={"TV (streaming)"}
      ControlIcon={(<Ctv
        className={`custom-img ${values.adLevel === "41TCTV"
          ? "with-border"
          : ""
          }`}
        alt="TV (streaming)"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "41TCTV"}
    >
    </WrapFormControlLabel>
  )
};

export const LHCtv = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"41TCTV"}
      textLabel={"Streaming"}
      ControlIcon={(<div><LHCtvIcon
        className={`custom-img ${values.adLevel === "41TCTV"
          ? "with-border"
          : ""
          }`}
        alt="Streaming"
        onClick={handleClick}
      />
      <LHCtvIconActive
        className={`custom-img ${values.adLevel === "41TCTV"
          ? ""
          : "hide"
          }`}
        alt="Streaming"
        onClick={handleClick}
      />
      </div>)}
      selected={values.adLevel === "41TCTV"}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const RadioIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"91"}
      textLabel={"Radio"}
      ControlIcon={(<Radio
        className={`custom-img ${values.adLevel === "91"
          ? "with-border"
          : ""
          }`}
        alt="Radio"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "91"}
    >
    </WrapFormControlLabel>
  )
};

export const LHRadio = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"91"}
      textLabel={"Radio"}
      ControlIcon={(<div><LHRadioIcon
        className={`custom-img ${values.adLevel === "91"
          ? "with-border"
          : ""
          }`}
        alt="Radio"
        onClick={handleClick}
      />
      <LHRadioIconActive
        className={`custom-img ${values.adLevel === "91"
          ? ""
          : "hide"
          }`}
        alt="Radio"
        onClick={handleClick}
      />
      </div>)}
      selected={values.adLevel === "91"}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const EventIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"99w"}
      textLabel={"Event"}
      ControlIcon={(<Event
        className={`custom-img ${values.adLevel === "99w"
          ? "with-border"
          : ""
          }`}
        alt="Event"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "99w"}
    >
    </WrapFormControlLabel>
  )
};

export const LHEvent = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"99w"}
      textLabel={"Event"}
      ControlIcon={(<div><LHEventIcon
        className={`custom-img ${values.adLevel === "99w"
          ? "with-border"
          : ""
          }`}
        alt="Event"
        onClick={handleClick}
      />
      <LHEventIconActive
        className={`custom-img ${values.adLevel === "99w"
          ? ""
          : "hide"
          }`}
        alt="Event"
        onClick={handleClick}
      />
      </div>)}
      selected={values.adLevel === "99w"}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const PrintAdIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"88w"}
      textLabel={"Print Ad"}
      ControlIcon={(<PrintAd
        className={`custom-img ${values.adLevel === "88w"
          ? "with-border"
          : ""
          }`}
        alt="Print Ad"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "88w"}
    >
    </WrapFormControlLabel>
  )
};

export const LHPrint = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"88w"}
      textLabel={"Print Ad"}
      ControlIcon={(<div><LHPrintIcon
        className={`custom-img ${values.adLevel === "88w"
          ? "with-border"
          : ""
          }`}
        alt="Print Ad"
        onClick={handleClick}
      />
      <LHPrintIconActive
        className={`custom-img ${values.adLevel === "88w"
          ? ""
          : "hide"
          }`}
        alt="Print Ad"
        onClick={handleClick}
      />
      </div>)}
      selected={values.adLevel === "88w"}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const PostcardIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"10w"}
      textLabel={"Postcard"}
      ControlIcon={(<Postcard
        className={`custom-img ${values.adLevel === "10w"
          ? "with-border"
          : ""
          }`}
        alt="Postcard"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "10w"}
    >
    </WrapFormControlLabel>
  )
};

export const LHPostcard = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"10w"}
      textLabel={"Postcard"}
      ControlIcon={(<div><LHPostcardIcon
        className={`custom-img ${values.adLevel === "10w"
          ? "with-border"
          : ""
          }`}
        alt="Postcard"
        onClick={handleClick}
      />
      <LHPostcardIconActive
        className={`custom-img ${values.adLevel === "10w"
          ? ""
          : "hide"
          }`}
        alt="Postcard"
        onClick={handleClick}
      />
      </div>)}
      selected={values.adLevel === "10w"}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const NascarIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"83"}
      textLabel={"NASCAR"}
      ControlIcon={(<Nascar
        className={`custom-img ${values.adLevel === "83"
          ? "with-border"
          : ""
          }`}
        alt="NASCAR"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "83"}
    >
    </WrapFormControlLabel>
  )
};

export const LHNascar = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"83"}
      textLabel={"NASCAR"}
      ControlIcon={(<div><LHNascarIcon
        className={`custom-img ${values.adLevel === "83"
          ? "with-border"
          : ""
          }`}
        alt="Nascar"
        onClick={handleClick}
      />
      <LHNascarIconActive
        className={`custom-img ${values.adLevel === "83"
          ? ""
          : "hide"
          }`}
        alt="Nascar"
        onClick={handleClick}
      />
      </div>)}
      selected={values.adLevel === "83"}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const LHPodcast = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"85"}
      textLabel={"Podcast"}
      ControlIcon={(<div><LHPodCastIcon
        className={`custom-img ${values.adLevel === "85"
          ? "with-border"
          : ""
          }`}
        alt="Podcast"
        onClick={handleClick}
      />
      <LHPodCastIconActive
        className={`custom-img ${values.adLevel === "85"
          ? ""
          : "hide"
          }`}
        alt="Podcast"
        onClick={handleClick}
      />
      </div>)}
      selected={values.adLevel === "85"}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const YoutubeIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"41PAV"}
      textLabel={"Youtube"}
      ControlIcon={(<Youtube
        className={`custom-img ${values.adLevel === "41PAV"
          ? "with-border"
          : ""
          }`}
        alt="Youtube"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "41PAV"}
    >
    </WrapFormControlLabel>
  )
};

export const LHYoutube = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"41PAV"}
      textLabel={"Youtube"}
      ControlIcon={(<div><LHYoutubeIcon
        className={`custom-img ${values.adLevel === "41PAV"
          ? "with-border"
          : ""
          }`}
        alt="Youtube"
        onClick={handleClick}
      />
      <LHYoutubeIconActive
        className={`custom-img ${values.adLevel === "41PAV"
          ? ""
          : "hide"
          }`}
        alt="Youtube"
        onClick={handleClick}
      />
      </div>)}
      selected={values.adLevel === "41PAV"}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const StairLiftsIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"Stair Lifts"}
      textLabel={"Stair Lifts"}
      ControlIcon={(<StairLifts
        className={`custom-img ${values.product.indexOf("Stair Lifts") >= 0
          ? "with-border"
          : ""
          }`}
        alt="Stair Lifts"
        onClick={handleClick}
      />)}
      selected={values.product.indexOf("Stair Lifts") >= 0}
    >
    </WrapFormControlLabel>
  )
};

export const WalkInIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"Tub or Shower"}
      textLabel={"Walk-In Shower or Bath"}
      ControlIcon={(<WalkIn
        className={`custom-img ${values.product.indexOf("Tub or Shower") >= 0
          ? "with-border"
          : ""
          }`}
        alt="Walk-In Shower or Bath"
        onClick={handleClick}
      />)}
      selected={values.product.indexOf("Tub or Shower") >= 0}
    >
    </WrapFormControlLabel>
  )
};

export const ReadyToHireIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={1}
      textLabel={"Ready to Hire"}
      ControlIcon={(<ReadyToHire
        className={`custom-img ${values.projectStatus === 1
          ? "with-border"
          : ""
          }`}
        alt="Ready to Hire"
        onClick={handleClick}
      />)}
      selected={values.projectStatus === 1}
    >
    </WrapFormControlLabel>
  )
};

export const LHReadyToHire = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"1"}
      textLabel={"Ready"}
      ControlIcon={(<div><LHReadyToHireIcon
        className={`custom-img ${values.projectStatus === 1
          ? "with-border"
          : ""
          }`}
        alt="Ready"
        onClick={handleClick}
      />
      <LHReadyToHireIconActive
        className={`custom-img ${values.projectStatus === 1
          ? ""
          : "hide"
          }`}
        alt="Ready"
        onClick={handleClick}
      />
      </div>)}
      selected={values.projectStatus === 1}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const PlanningIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={2}
      textLabel={"Planning & Budgeting"}
      ControlIcon={(<Planning
        className={`custom-img ${values.projectStatus === 2
          ? "with-border"
          : ""
          }`}
        alt="Planning & Budgeting"
        onClick={handleClick}
      />)}
      selected={values.projectStatus === 2}
    >
    </WrapFormControlLabel>
  )
};

export const LHPlanning = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"2"}
      textLabel={"Planning"}
      ControlIcon={(<div><LHPlanningIcon
        className={`custom-img ${values.projectStatus === 2
          ? "with-border"
          : ""
          }`}
        alt="Planning"
        onClick={handleClick}
      />
      <LHPlanningIconActive
        className={`custom-img ${values.projectStatus === 2
          ? ""
          : "hide"
          }`}
        alt="Planning"
        onClick={handleClick}
      />
      </div>)}
      selected={values.projectStatus === 2}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const HomeOwnerIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"homeOwner"}
      textLabel={"Homeowner"}
      ControlIcon={(<HomeOwner
        className={`custom-img ${values.roleType === "homeOwner"
          ? "with-border"
          : ""
          }`}
        alt="Homeowner"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "homeOwner"}
    >
    </WrapFormControlLabel>
  )
};

export const LHHomeOwner = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"homeOwner"}
      textLabel={"Homeowner"}
      ControlIcon={(<div><LHHomeownerIcon
        className={`custom-img ${values.roleType === "homeOwner"
          ? "with-border"
          : ""
          }`}
        alt="Homeowner"
        onClick={handleClick}
      />
      <LHHomeownerIconActive
        className={`custom-img ${values.roleType === "homeOwner"
          ? ""
          : "hide"
          }`}
        alt="Homeowner"
        onClick={handleClick}
      />
      </div>)}
      selected={values.adLevel === "homeOwner"}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const FamilyMemberIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"familyMember"}
      textLabel={"Family Owner"}
      ControlIcon={(<FamilyMember
        className={`custom-img ${values.roleType === "familyMember"
          ? "with-border"
          : ""
          }`}
        alt="Family Owner"
        onClick={handleClick}
      />)}
      selected={values.roleType === "familyMember"}
    >
    </WrapFormControlLabel>
  )
};

export const LHHouseHead = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"familyMember"}
      textLabel={"House Head"}
      ControlIcon={(<div><LHHouseHeadIcon
        className={`custom-img ${values.roleType === "familyMember"
          ? "with-border"
          : ""
          }`}
        alt="House Head"
        onClick={handleClick}
      />
      <LHHouseHeadIconActive
        className={`custom-img ${values.roleType === "familyMember"
          ? ""
          : "hide"
          }`}
        alt="House Head"
        onClick={handleClick}
      />
      </div>)}
      selected={values.roleType === "familyMember"}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const CareTakerIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"careTaker"}
      textLabel={"Care Taker"}
      ControlIcon={(<CareTaker
        className={`custom-img ${values.roleType === "careTaker"
          ? "with-border"
          : ""
          }`}
        alt="Care Taker"
        onClick={handleClick}
      />)}
      selected={values.roleType === "careTaker"}
    >
    </WrapFormControlLabel>
  )
};

export const LHCareTaker = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"careTaker"}
      textLabel={"Care Taker"}
      ControlIcon={(<div><LHCareTakerIcon
        className={`custom-img ${values.roleType === "careTaker"
          ? "with-border"
          : ""
          }`}
        alt="Care Taker"
        onClick={handleClick}
      />
      <LHCareTakerIconActive
        className={`custom-img ${values.roleType === "careTaker"
          ? ""
          : "hide"
          }`}
        alt="Care Taker"
        onClick={handleClick}
      />
      </div>)}
      selected={values.roleType === "careTaker"}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const YesIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"yes"}
      textLabel={"Yes"}
      ControlIcon={(<YesSvg
        className={`custom-img ${values.financing === "yes"
          ? "with-border"
          : ""
          }`}
        alt="Yes"
        onClick={handleClick}
      />)}
      selected={values.financing === "yes"}
    >
    </WrapFormControlLabel>
  )
};

export const LHYes= ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"yes"}
      textLabel={"Yes"}
      ControlIcon={(<div><LHYesIcon
        className={`custom-img ${values.financing === "yes"
          ? "with-border"
          : ""
          }`}
        alt="Yes"
        onClick={handleClick}
      />
      <LHYesIconActive
        className={`custom-img ${values.financing === "yes"
          ? ""
          : "hide"
          }`}
        alt="Yes"
        onClick={handleClick}
      />
      </div>)}
      selected={values.financing === "yes"}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const NoIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"no"}
      textLabel={"No"}
      ControlIcon={(<NoSvg
        className={`custom-img ${values.financing === "no"
          ? "with-border"
          : ""
          }`}
        alt="No"
        onClick={handleClick}
      />)}
      selected={values.financing === "no"}
    >
    </WrapFormControlLabel>
  )
};

export const LHNo = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"no"}
      textLabel={"No"}
      ControlIcon={(<div><LHNoIcon
        className={`custom-img ${values.financing === "no"
          ? "with-border"
          : ""
          }`}
        alt="No"
        onClick={handleClick}
      />
      <LHNoIconActive
        className={`custom-img ${values.financing === "no"
          ? ""
          : "hide"
          }`}
        alt="No"
        onClick={handleClick}
      />
      </div>)}
      selected={values.financing === "no"}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const LHWellWater = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"Well Water"}
      textLabel={"Well Water"}
      ControlIcon={(<div><LHWellWaterIcon
        className={`custom-img ${values.typeofwater === 'Well Water'
          ? "with-border"
          : ""
          }`}
        alt="Well Water"
        onClick={handleClick}
      />
      <LHWellWaterIconActive
        className={`custom-img ${values.typeofwater === 'Well Water'
          ? ""
          : "hide"
          }`}
        alt="Well Water"
        onClick={handleClick}
      />
      </div>)}
      selected={values.typeofwater === 'Well Water'}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const LHCityWater = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"City Water"}
      textLabel={"City Water"}
      ControlIcon={(<div><LHCityWaterIcon
        className={`custom-img ${values.typeofwater === 'City Water'
          ? "with-border"
          : ""
          }`}
        alt="City Water"
        onClick={handleClick}
      />
      <LHCityWaterIconActive
        className={`custom-img ${values.typeofwater === 'City Water'
          ? ""
          : "hide"
          }`}
        alt="City Water"
        onClick={handleClick}
      />
      </div>)}
      selected={values.typeofwater === 'City Water'}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const LHUnsure = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"Unsure"}
      textLabel={"Unsure"}
      ControlIcon={(<div><LHUnsureIcon
        className={`custom-img ${values.typeofwater === 'Unsure'
          ? "with-border"
          : ""
          }`}
        alt="Unsure"
        onClick={handleClick}
      />
      <LHUnsureIconActive
        className={`custom-img ${values.typeofwater === 'Unsure'
          ? ""
          : "hide"
          }`}
        alt="Unsure"
        onClick={handleClick}
      />
      </div>)}
      selected={values.typeofwater === 'Unsure'}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const LHWaterOdor = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"1"}
      textLabel={"Yes"}
      ControlIcon={(<div><LHWaterOdorIcon
        className={`custom-img ${values.watercolor === 1
          ? "with-border"
          : ""
          }`}
        alt="Yes"
        onClick={handleClick}
      />
      <LHWaterOdorIconActive
        className={`custom-img ${values.watercolor === 1
          ? ""
          : "hide"
          }`}
        alt="Yes"
        onClick={handleClick}
      />
      </div>)}
      selected={values.watercolor === 1}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const LHNoOdor = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"2"}
      textLabel={"No"}
      ControlIcon={(<div><LHNoOdorIcon
        className={`custom-img ${values.watercolor === 2
          ? "with-border"
          : ""
          }`}
        alt="No"
        onClick={handleClick}
      />
      <LHNoOdorIconActive
        className={`custom-img ${values.watercolor === 2
          ? ""
          : "hide"
          }`}
        alt="No"
        onClick={handleClick}
      />
      </div>)}
      selected={values.watercolor === 2}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const LHImmediately = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"immediately"}
      textLabel={"Immediately"}
      ControlIcon={(<div><LHReadyToHireIcon
        className={`custom-img ${values.projectTime === "immediately"
          ? "with-border"
          : ""
          }`}
        alt="immediately"
        onClick={handleClick}
      />
      <LHReadyToHireIconActive
        className={`custom-img ${values.projectTime === "immediately"
          ? ""
          : "hide"
          }`}
        alt="immediately"
        onClick={handleClick}
      />
      </div>)}
      selected={values.projectTime === "immediately"}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const LHWeeks= ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"1-2 Weeks"}
      textLabel={"1-2 Weeks"}
      ControlIcon={(<div><LHPlanningIcon
        className={`custom-img ${values.projectTime === "1-2 Weeks"
          ? "with-border"
          : ""
          }`}
        alt="1-2 Weeks"
        onClick={handleClick}
      />
      <LHPlanningIconActive
        className={`custom-img ${values.projectTime === "1-2 Weeks"
          ? ""
          : "hide"
          }`}
        alt="1-2 Weeks"
        onClick={handleClick}
      />
      </div>)}
      selected={values.projectTime === "1-2 Weeks"}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const LHFlexible= ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"Flexible"}
      textLabel={"Flexible"}
      ControlIcon={(<div><LHFlexibleIcon
        className={`custom-img ${values.projectTime === "Flexible"
          ? "with-border"
          : ""
          }`}
        alt="Flexible"
        onClick={handleClick}
      />
      <LHFlexibleIconActive
        className={`custom-img ${values.projectTime === "Flexible"
          ? ""
          : "hide"
          }`}
        alt="Flexible"
        onClick={handleClick}
      />
      </div>)}
      selected={values.projectTime === "Flexible"}
      textClass={'small'}
    >
    </WrapFormControlLabel>
  )
};

export const LHWSOnlineIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={`41 lf-icon-control-label ${values.adLevel === "41"
        ? "selected-border"
        : ""
        }`}
      textLabel={"Online"}
      ControlIcon={(<OnlineIconLHWS
        className={`custom-img ad-level-img ${values.adLevel === "41"
          ? "selected"
          : ""
          }`}
        alt="Online"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "41"}
    >
    </WrapFormControlLabel>
  )
};

export const LHWSEventIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={`99w lf-icon-control-label ${values.adLevel === "99w"
        ? "selected-border"
        : ""
        }`}
      textLabel={"Event"}
      ControlIcon={(<EventIconLHWS
        className={`custom-img ad-level-img ${values.adLevel === "99w"
          ? "selected"
          : ""
          }`}
        alt="Event"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "99w"}
    >
    </WrapFormControlLabel>
  )
};

export const LHWSPrintAdIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={`88w lf-icon-control-label ${values.adLevel === "88w"
        ? "selected-border"
        : ""
        }`}
      textLabel={"Print Ad"}
      ControlIcon={(<PrintAdIconLHWS
        className={`custom-img ad-level-img ${values.adLevel === "88w"
          ? "selected"
          : ""
          }`}
        alt="Print Ad"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "88w"}
    >
    </WrapFormControlLabel>
  )
};

export const LHWSPostcardIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={`10w lf-icon-control-label ${values.adLevel === "10w"
        ? "selected-border"
        : ""
        }`}
      textLabel={"PostCard"}
      ControlIcon={(<PostcardIconLHWS
        className={`custom-img ad-level-img ${values.adLevel === "10w"
          ? "selected"
          : ""
          }`}
        alt="PostCard"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "10w"}
    >
    </WrapFormControlLabel>
  )
};

export const LHWSRadioIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={`91 lf-icon-control-label ${values.adLevel === "91"
        ? "selected-border"
        : ""
        }`}
      textLabel={"Radio"}
      ControlIcon={(<RadioIconLHWS
        className={`custom-img ad-level-img ${values.adLevel === "91"
          ? "selected"
          : ""
          }`}
        alt="Radio"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "91"}
    >
    </WrapFormControlLabel>
  )
};

export const LHWSTVIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={`81 lf-icon-control-label ${values.adLevel === "81"
        ? "selected-border"
        : ""
        }`}
      textLabel={"TV"}
      ControlIcon={(<TVIconLHWS
        className={`custom-img ad-level-img ${values.adLevel === "81"
          ? "selected"
          : ""
          }`}
        alt="TV"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "81"}
    >
    </WrapFormControlLabel>
  )
};

export const LHWSCTVIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={`41TCTV lf-icon-control-label ${values.adLevel === "41TCTV"
        ? "selected-border"
        : ""
        }`}
      textLabel={"TV (streaming)"}
      ControlIcon={(<CtvLHWS
        className={`custom-img ad-level-img ${values.adLevel === "41TCTV"
          ? "selected"
          : ""
          }`}
        alt="TV (streaming)"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "41TCTV"}
    >
    </WrapFormControlLabel>
  )
};

export const PodCastIcon = ({ handleClick }) => {
  const { values } = useFormikContext();
  return (
    <WrapFormControlLabel
      keyText={"85"}
      textLabel={"Podcast"}
      ControlIcon={(<PodCast
        className={`custom-img podcast-img ${values.adLevel === "85"
          ? "with-border"
          : ""
          }`}
        alt="Youtube"
        onClick={handleClick}
      />)}
      selected={values.adLevel === "85"}
    >
    </WrapFormControlLabel>
  )
};
